import DeviceLogList from '../TR/DeviceLogs/DeviceLogList';
import ClockAssignmentList from '../TR/ClockAssignments/ClockAssignmentsList';
import EmployeeList from '../HRIS/Employee/EmployeeList';
import EmployeeUserView from '../HRIS/Employee/EmployeeUserView';
import GroupList from '../HRIS/Groups/GroupList';
import ScheduleList from '../TA/ScheduleProfile/ScheduleModule';
import ShiftSchedule from '../TA/Schedules/ShiftScheduleList';
import DeviceList from '../TR/Devices/DeviceList';
import OvertimeList from '../TA/Transactions/OvertimeList';
import CutoffList from '../TA/Cutoff/CutoffModule';
import Holiday from '../TA/Holiday/Holiday';
import OfficialBusinessList from '../TA/Transactions/OfficialBusinessList'
import LeaveList from '../TA/Transactions/LeaveList';
import LeaveProfileList from '../TA/Leaves/LeaveProfile/LeaveProfileList';
import DTRCorrectionList from '../TA/Transactions/DTRCorrectionList';
import ChangeScheduleList from '../TA/Transactions/ChangeScheduleList';
import CompensatoryTimeOffList from '../TA/Transactions/CompensatoryTimeOffList';
import LeaveAdjustmentList from '../TA/Transactions/LeaveAdjustmentList';
// import DeviceCommandMain from '../TR/Devices/DeviceCommandMain';  
import ReportBase from '../Reports/ReportBase';
import GovernmentForms from '../Reports/GovernmentForms';
import UserRightsManagement from '../Commons/UserRightsManagement'
import AnnouncementList from './Announcements/AnnouncementList';
import AuditTrail from '../Commons/AuditTrail';
import LeaveTypeList from '../TA/Leaves/LeaveTypeList';
// import Monitoring from '../TR/Monitoring/Monitoring'; 
import ChangeOfRestdayList from '../TA/Transactions/ChangeOfRestdayList';
import SystemSetting from '../Commons/SystemSettingsManagement';
// import Licensing from '../Commons/Licensing';
import RemoteClockAssignmentList from '../TR/RemoteClockAssignments/RemoteClockAssignmentList';

import AlphanumericTaxCodeProfile from '../Payroll/ATCProfile/AlphanumericTaxCodeProfile';
import PayrollProfileList from '../Payroll/PayrollProfile/PayrollProfileModule';
import PayrollRateProfileList from '../Payroll/PayrollRateProfile/PayrolllRateProfileModule';
import PayrollAdjustmentList from '../Payroll/PayrollAdjustment/PayrollAdjustmentList';
import PayrollAdjustmentTypeList from '../Payroll/PayrollAdjustment/PayrollAdjustmentTypeList';
import PayrollLoanList from '../Payroll/PayrollLoan/PayrollLoanModule';
import PayrollLoanTypeList from '../Payroll/PayrollLoan/PayrollLoanTypeList';
import PayrollBankList from '../Payroll/PayrollBank/PayrollBankList';
import OffCyclePayList from '../Payroll/OffCyclePay/OffCyclePayList';
import OffCyclePayTypeList from '../Payroll/OffCyclePay/OffCyclePayTypeList';
import EmailSetting from '../Commons/EmailSettings';
import GovernmentTable from '../Payroll/GovernmentTable/GovernmentTable';
import FringeBenefit from '../Payroll/FringeBenefit/FringeBenefit';
import PayrollLoanModule from '../Payroll/PayrollLoan/PayrollLoanModule';
import PayrollProfileModule from '../Payroll/PayrollProfile/PayrollProfileModule';
import PayrollRateProfileModule from '../Payroll/PayrollRateProfile/PayrolllRateProfileModule';
import AllowanceProfileModule from '../Payroll/AllowanceProfile/AllowanceProfileModule';
import AllowanceTypeModule from '../Payroll/AllowanceType/AllowanceTypeModule';
import CutoffModule from '../TA/Cutoff/CutoffModule';

import AnnouncementTrail from './Announcements/AnnouncementTrail';
import AttendanceTrail from './Attendance/AttendanceTrail';
import PendingCountTrail from './Transactions/PendingCountTrail';
import PieceWork from '../Payroll/PieceWork/PieceWork';
import PieceRate from '../Payroll/PieceRate/PieceRate';
import PreviousEmployerList from '../Payroll/PreviousEmployer/PreviousEmployerList';

export var _modulesList =
    [
        {
            GroupName: "HRIS",
            Modules: [
                EmployeeUserView,
                EmployeeList,
            ]
        },
        {
            GroupName: "Time Attendance",
            Modules: [
                CutoffList,
                Holiday,
                DeviceLogList,
            ],
        },
        {
            GroupName: "Transactions",
            Modules: [
                OvertimeList,
                OfficialBusinessList,
                LeaveList,
                LeaveAdjustmentList,
                DTRCorrectionList,
                ChangeScheduleList,
                ShiftSchedule,
                CompensatoryTimeOffList,
                ChangeOfRestdayList,
            ]
        }, {
            GroupName: "Payroll Settings",
            Modules: [
                PayrollLoanTypeList,
                PayrollAdjustmentTypeList,
                PayrollBankList,
                OffCyclePayTypeList,
                GovernmentTable,
                PieceRate,
                AllowanceProfileModule,
                AllowanceTypeModule,
            ]
        }, {
            GroupName: "Payroll Transactions",
            Modules: [
                PayrollAdjustmentList,
                PayrollLoanList,
                OffCyclePayList,
                FringeBenefit,
                PieceWork,
                PreviousEmployerList
            ]
        },
        {
            GroupName: "Devices",
            Modules: [
                DeviceList,
                // DeviceCommandMain,
                ClockAssignmentList,
                // Monitoring,
            ]
        },
        {
            GroupName: "Profiles",
            Modules: [
                ScheduleList,
                AlphanumericTaxCodeProfile,
                PayrollProfileList,
                PayrollRateProfileList,
                UserRightsManagement,
                AllowanceProfileModule,
            ]

        },
        {
            GroupName: "Reports",
            Modules: [
                ReportBase,
                GovernmentForms
            ]
        },
        {
            GroupName: "Administration",
            Modules: [
                GroupList,
                // MaxOTList,
                // JobLevelList,
                AnnouncementList,
                AuditTrail,
                LeaveProfileList,
                LeaveTypeList,
                //Monitoring,
                SystemSetting,
                EmailSetting,
                // Licensing
            ]
        }
    ];

export var _employeeModules = [
    {
        ModuleName: 'Employee Management',
        Module: EmployeeList
    },
    {
        ModuleName: 'Employee',
        Module: EmployeeUserView
    },
    {
        ModuleName: 'Employee Groups',
        Module: GroupList
    }
]

export var _payrollTransactions =
    [
        {
            ModuleName: 'Payroll Adjustment',
            Module: PayrollAdjustmentList
        },
        {
            ModuleName: 'Payroll Loan',
            Module: PayrollLoanModule
        },
        {
            ModuleName: 'Off-cycle Pay',
            Module: OffCyclePayList
        },
        {
            ModuleName: 'Fringe Benefit',
            Module: FringeBenefit
        },
        {
            ModuleName: 'Piece Work',
            Module: PieceWork
        },
        {
            ModuleName: 'Previous Employer',
            Module: PreviousEmployerList
        },
    ];

export var _payrollSettings =
    [
        {
            ModuleName: 'Payroll Loan Type',
            Module: PayrollLoanTypeList
        },
        {
            ModuleName: 'Payroll Adjustment Type',
            Module: PayrollAdjustmentTypeList
        },
        {
            ModuleName: 'Payroll Bank',
            Module: PayrollBankList
        },
        {
            ModuleName: 'Off-cycle Pay Type',
            Module: OffCyclePayTypeList
        },
        {
            ModuleName: 'Government Table',
            Module: GovernmentTable
        },
        {
            ModuleName: 'Payroll Profile',
            Module: PayrollProfileModule
        },
        {
            ModuleName: 'Payroll Rate Profile',
            Module: PayrollRateProfileModule
        },
        {
            ModuleName: 'Tax Code Profile',
            Module: AlphanumericTaxCodeProfile
        },
        {
            ModuleName: 'Piece Rate',
            Module: PieceRate
        },
        {
            ModuleName: 'Allowance Profile',
            Module: AllowanceProfileModule
        },
        {
            ModuleName: 'Allowance Type',
            Module: AllowanceTypeModule
        },
    ];

export var _transactions =
    [
        {
            ModuleName: 'Time Entries',
            Module: DeviceLogList
        },
        {
            ModuleName: 'Overtime',
            Module: OvertimeList
        },
        {
            ModuleName: 'Official Business',
            Module: OfficialBusinessList
        },
        {
            ModuleName: 'DTR Correction',
            Module: DTRCorrectionList
        },
        {
            ModuleName: 'Offset',
            Module: CompensatoryTimeOffList
        },
        {
            ModuleName: 'Leave',
            Module: LeaveList
        },
        {
            ModuleName: 'Leave Adjustment',
            Module: LeaveAdjustmentList
        },
        {
            ModuleName: 'Change Schedule',
            Module: ChangeScheduleList
        },
        {
            ModuleName: 'Change Restday',
            Module: ChangeOfRestdayList
        },
    ];

export var _leaveModules =
    [

    ];

export var _deviceModules =
    [
        {
            ModuleName: 'Devices',
            Module: DeviceList
        },
        // {
        //     ModuleName: 'Clock Assignment',
        //     Module: ClockAssignmentList
        // },
    ];

export var _reportModules =
    [
        {
            ModuleName: 'Reports',
            Module: ReportBase
        },
        {
            ModuleName: 'Government Reports',
            Module: GovernmentForms
        },
    ];

export var _adminModules =
    [
        {
            ModuleName: 'Announcement',
            Module: AnnouncementList
        },
        {
            ModuleName: 'Audit Trail',
            Module: AuditTrail
        },
        {
            ModuleName: 'Holiday',
            Module: Holiday
        },
        {
            ModuleName: 'Leave Profile',
            Module: LeaveProfileList
        },
        {
            ModuleName: 'Leave Types',
            Module: LeaveTypeList
        },
        {
            ModuleName: 'Schedule',
            Module: ShiftSchedule
        },
        {
            ModuleName: 'Schedule Profile',
            Module: ScheduleList
        },
        {
            ModuleName: 'User Rights',
            Module: UserRightsManagement
        },
        {
            ModuleName: "System Settings",
            Module: SystemSetting
        },
        {
            ModuleName: 'Remote Clock Assignment',
            Module: RemoteClockAssignmentList
        }
    ];
export var _payrollModules =
    [
        {
            ModuleName: 'Cutoff Management',
            Module: CutoffModule
        },
    ];
export var _dashboardModules =
    [
        {
            ModuleName: "Attendance",
            Module: AttendanceTrail
        },
        {
            ModuleName: "Pending Transaction",
            Module: PendingCountTrail
        },
    ];