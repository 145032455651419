import React, { Component } from 'react';
import Select from 'react-select';
import { ajaxPost } from '../../ajax';
import { Input, Button, Table, Tab, Pagination, Icon, Menu } from 'semantic-ui-react';
import { ajaxPostDownload } from '../../utils';
import EmployeeSelection from '../../Commons/EmployeeSelection';
import PdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';

const moment = require('moment');

const filterOptions = [
    // { label: "With Previous Employer", value: 0 },
    // { label: "No Previous Employer", value: 1 },
    // { label: "Terminated Before December 31", value: 2 },
    // { label: "Minimum Wage Earner", value: 3 },
        { label: "Non-Minimum Wage Earner", value: 0 },
        // { label: "No Previous Employer", value: 1 },
        // { label: "Terminated Before December 31", value: 2 },
        { label: "Minimum Wage Earner", value: 3 },
]

const borderStyle = {
    border: '1px solid',
    borderBottom: '0px none'
}

const arrangementBorderStyle = {
    border: '1px solid',
    borderTop: '0px none'
}

class Alphalist extends Component {

    constructor(props) {
        super(props);
        this.state = {
            year: moment().format("YYYY"),
            selectedFilter: 0,
            isLoading: false,
            alphalistData: [],
            activePage: 1,
            totalPages: 0,
            isExportPDFLoading: false,
            isExportExcelLoading: false,
            employeeSelectionFilter: {
                employeeIds: [],
                selectedCompanies: [],
                selectedDepartments: [],
                selectedPositions: [],
                selectedLocations: [],
                isSelectAll: false
            }
        }
    }

    componentWillMount = () => {
        if (this.props.prevState !== null)
            this.state = this.props.prevState;
    }

    componentWillUnmount = () => {
        this.props.unmountCallback(this.state, 'Alphalist');
    }

    processPdfData = (pdfData) => {
        const { vfs } = vfsFonts.pdfMake;
        PdfMake.vfs = vfs;

        PdfMake.createPdf(pdfData).open();
    }

    generateAlphalist(callback, startIndex, itemCount) {
        if (this.state.employeeSelectionFilter.isSelectAll === false && this.state.employeeSelectionFilter.employeeIds.length === 0) {
            alert("No Employees to be Generated");
            this.setState({ isExportPDFLoading: false });
            this.setState({ isExportExcelLoading: false });
            return;
        }

        ajaxPost({
            url: 'api/governmentforms/GenerateAlphalist',
            data: {
                filter: this.state.employeeSelectionFilter,
                type: this.state.selectedFilter,
                year: this.state.year,
                startIndex: startIndex,
                itemCount: itemCount,
            },
            onSuccess: (value) => {
                this.checkStatus(value, callback);
            },
            finally: () => { }
        });
    }

    checkStatus(processId, callback) {
        ajaxPost({
            data: { processId: processId },
            url: 'api/BackgroundProcess/GetStatus',
            onSuccess: data => {
                if (data.Status != "PROCESSING") {
                    callback(processId)
                }
                else {
                    this.checkStatus(processId, callback);
                }
            },
            finally: () => { }
        })
    }

    onGenerateClick = () => {
        this.setState({ isGenerateLoading: true, activePage: 1 },
            () =>
                this.generateAlphalist((processId) => {
                    ajaxPost({
                        url: 'api/governmentforms/GetAlphalist',
                        data: processId,
                        onSuccess: (data) => {
                            this.setState({ alphalistData: data.Content, totalPages: data.Count });
                            this.setState({ isGenerateLoading: false });
                        },
                        finally: () => { }
                    });
                }, (this.state.activePage - 1) * 20, 20)
        )
    }

    onExportExcel = () => {
        this.setState({ isExportExcelLoading: true });
        this.generateAlphalist((processId) => {
            ajaxPostDownload(
                'api/governmentforms/AlphalistExportToExcel',
                {
                    type: this.state.selectedFilter,
                    processId: processId
                },
                'Alphalist.xlsx',
                () => {

                },
                (error) => {
                    var errorMessage = "An error has occurred";
                },
                () => {
                    this.setState({ isExportExcelLoading: false });
                }
            )
        }, 0, 20000)
    }

    onExportPDF = () => {
        this.setState({ isExportPDFLoading: true });
        this.generateAlphalist((processId) => {
            ajaxPost({
                url: 'api/governmentforms/AlphalistExportToPDF',
                data: {
                    type: this.state.selectedFilter,
                    processId: processId
                },
                onSuccess: (data) => {
                    this.processPdfData(data);
                },
                finally: () => {
                    this.setState({ isExportPDFLoading: false });
                }
            }
            )
        }, 0, 20000)
    }

    onPaginationChange = (e, { activePage }) => {
        if (activePage === this.state.activePage)
            return;

        this.setState({ activePage },
            () => this.generateAlphalist((processId) => {
                ajaxPost({
                    url: 'api/governmentforms/GetAlphalist',
                    data: processId,
                    onSuccess: (data) => {
                        this.setState({ alphalistData: data.Content, totalPages: data.Count });
                        this.setState({ isGenerateLoading: false });
                    },
                    finally: () => { }
                });
            }, (this.state.activePage - 1) * 20, 20)
        )
    }

    callbackFunction(filter, selectedIds, isSelectAll) {
        var { employeeSelectionFilter } = this.state;

        employeeSelectionFilter.employeeIds = selectedIds;
        employeeSelectionFilter.selectedCompanies = filter.selectedCompanies;
        employeeSelectionFilter.selectedDepartments = filter.selectedDepartments;
        employeeSelectionFilter.selectedPositions = filter.selectedPositions;
        employeeSelectionFilter.selectedLocations = filter.selectedLocations;
        employeeSelectionFilter.isSelectAll = isSelectAll;

        this.setState({ employeeSelectionFilter })
    }

    render() {
        var { selectedFilter } = this.state;
        return (
            <div>
                <h1>Alphalist</h1>

                <div style={{ display: 'flex' }}>
                    <div style={{ height: window.innerHeight - 203 }}>
                        <EmployeeSelection height={'100%'} width={300} callback={this.callbackFunction.bind(this)} />
                    </div>

                    <div style={{ width: window.innerWidth - 720 }}>
                        <div style={{ display: 'flex', width: '100%' }}>
                            <Input value={this.state.year} onChange={(e) => this.setState({ year: e.target.value })} />
                            <div style={{ width: '100%' }}>
                                <Select options={filterOptions} value={filterOptions.find(x => x.value === selectedFilter)} onChange={(e) => this.setState({ selectedFilter: e.value, alphalistData: [] })} />
                            </div>
                            <Button content='Generate' loading={this.state.isGenerateLoading} disabled={this.state.isGenerateLoading} onClick={this.onGenerateClick.bind(this)} />
                        </div>

                        <div style={{ marginTop: '10px', marginBottom: '5px' }}>
                            <Button content='Export To Excel' loading={this.state.isExportExcelLoading} disabled={this.state.isExportExcelLoading} onClick={this.onExportExcel} />
                            <Button content='Export To PDF' loading={this.state.isExportPDFLoading} disabled={this.state.isExportPDFLoading} onClick={this.onExportPDF} />

                            <Pagination
                                floated='right'
                                activePage={this.state.activePage}
                                pointing
                                secondary
                                firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                                lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                                prevItem={{ content: <Icon name='angle left' />, icon: true }}
                                nextItem={{ content: <Icon name='angle right' />, icon: true }}
                                size='mini'
                                siblingRange={2}
                                boundaryRange={0}
                                totalPages={Math.ceil(this.state.totalPages / 20)}
                                onPageChange={this.onPaginationChange.bind(this)}
                            />
                        </div>

                        <div style={{ width: '98%', height: window.innerHeight - 293, overflowX: 'auto' }}>
                            {/* {(selectedFilter === 1 || selectedFilter === 2) && <Table Fixed>  */}
                            {/*no previous employer and terminated before december*/}
                                {/* <Table.Row>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={3}>SEQ NO</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={3}>TIN</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' colspan={3}>NAME OF EMPLOYEES</Table.HeaderCell>
                                    {selectedFilter === 2 && <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' colspan={2}>Inclusive Date of Employment</Table.HeaderCell>}
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' colspan={10}>(4) GROSS COMPENSATION INCOME</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} colspan={2}>EXEMPTION</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={3}>Premium Paid on Health and/or Hospital Insurance</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={3}>Net Taxable Compensation Income</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={3}>TAX DUE (JAN.-DEC.)</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={3}>TAX WITHHELD (JAN. - NOV.)</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' colspan={2}>YEAR END ADJUSTMENT (10a or 10b)</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={3}>AMOUNT OF TAX  WITHHELD AS ADJUSTED (to be reflected in 2316)</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={3}> Substitute Filing? Yes/No</Table.HeaderCell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} >Last Name</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} >First Name</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} >Middle Name</Table.HeaderCell>
                                    {selectedFilter === 2 && <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2}>From</Table.HeaderCell>}
                                    {selectedFilter === 2 && <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2}>To</Table.HeaderCell>}
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} >Gross Compensation Income</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' colspan={5}>NON-TAXABLE</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' colspan={4}>TAXABLE</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2}>AMOUNT WITHHELD AND PAID FOR IN DECEMBER)</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2}>OVER WITHHELD TAX REFUNDED TO EMPLOYEE</Table.HeaderCell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' >13th Month Pay &amp; Other Benefits</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' >Deminimis Benefits</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' >SSS,GSIS,PHIC &amp; Pag-ibig Contributions and Union Dues</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' >Salaries &amp; Other Forms of Compensation</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' >Total Non-Taxable/Exempt Compensation Income</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' >Basic Salary</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' >13th Month Pay &amp; Other Benefits</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' >Salaries &amp; Other Forms of Compensation</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' >Total Taxable Compensation Income</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' > Code</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' > Amount</Table.HeaderCell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(1)</Table.Cell>
                                    <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(2)</Table.Cell>
                                    <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >3(a)</Table.Cell>
                                    <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >3(b)</Table.Cell>
                                    <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >3(c)</Table.Cell>
                                    {selectedFilter === 2 && <Table.Cell style={arrangementBorderStyle}></Table.Cell>}
                                    {selectedFilter === 2 && <Table.Cell style={arrangementBorderStyle}></Table.Cell>}
                                    <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >4(a)</Table.Cell>
                                    <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >4(b)</Table.Cell>
                                    <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >4(c)</Table.Cell>
                                    <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >4(d)</Table.Cell>
                                    <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >4(e)</Table.Cell>
                                    <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >4(f)</Table.Cell>
                                    <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >4(g)</Table.Cell>
                                    <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >4(h)</Table.Cell>
                                    <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >4(i)</Table.Cell>
                                    <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >4(j)</Table.Cell>
                                    <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >5(a)</Table.Cell>
                                    <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >5(b)</Table.Cell>
                                    <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(6)</Table.Cell>
                                    <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(7)</Table.Cell>
                                    <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(8)</Table.Cell>
                                    <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(9)</Table.Cell>
                                    <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(10a)=(8)-(9)</Table.Cell>
                                    <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(10b)=(9)-(8)</Table.Cell>
                                    <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(11)=(9+10a) or (9-10b)</Table.Cell>
                                    <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(12)</Table.Cell>
                                </Table.Row>

                                {this.state.alphalistData.map(x =>
                                    <Table.Row>
                                        <Table.Cell> {x.SequenceNumber} </Table.Cell>
                                        <Table.Cell> {x.Tin} </Table.Cell>
                                        <Table.Cell> {x.LastName} </Table.Cell>
                                        <Table.Cell> {x.FirstName} </Table.Cell>
                                        <Table.Cell> {x.MiddleName} </Table.Cell>
                                        {selectedFilter === 2 && <Table.Cell>{x.DateHired}</Table.Cell>}
                                        {selectedFilter === 2 && <Table.Cell>{x.DateResigned}</Table.Cell>}
                                        <Table.Cell> {parseFloat(x.GrossCompensationIncome).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.Pay13thOtherNT).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.DeMinimis).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.GovContri).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.SalOtherCompNT).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.Pay13thOtherNT + x.DeMinimis + x.GovContri + x.SalOtherCompNT).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.BasicSalaryT).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.Pay13thOtherT).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.SalOtherCompT).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.TotalCompIncomeT).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {x.ExemptTaxCode} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.ExemptTaxAmount).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.PemiumPaidHealthHospInsure).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.NetTaxableCompIncome).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.TaxDue).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.TaxWithHeldNov).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.TaxWithHeldDec).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.OverWithHeld).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.TaxWithHeldAdjusted).toFixed(2)} </Table.Cell>
                                    </Table.Row>
                                )} */}
                            {/* </Table>} */}

                            {(selectedFilter === 0) && <Table Fixed> {/*with previous employer*/}
                            <Table.Row>
                                {/* (1) */}
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={3}>SEQ NO</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' colspan={3}>NAME OF EMPLOYEES</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' colspan={15}>P R E S E N T &nbsp;&nbsp; E M P L O Y E R</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' colspan={15}>P R E V I O U S &nbsp;&nbsp; E M P L O Y E R</Table.HeaderCell>

                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={3} >Total Taxable Compensation Income(Present and Previous employer)</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={3} >Tax Due</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={3} >Tax Withheld Previous Employer</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={3} >Tax Withheld Present Employer</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={3} >Year-end Adjustment (16a or 16b) AMT Withheld & Paid for In December or Last Salary</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={3} >Over Withheld Tax refunded to Employee</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={3} >Amount of Tax withheld as Adjusted</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={3} >Substitute filling YES/NO ***</Table.HeaderCell>
                            </Table.Row>

                            <Table.Row>
                                {/* (2a)(2b)(2c),  */}
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} >Last Name</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} >First Name</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} >Middle Name</Table.HeaderCell>

                                {/* (3), (4), (5a), (5b), (6), 7a=(7f+7j)  */}
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} >Nationality/Resident(for Foreigners Only)</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} >Current Employement Status(*)</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} >Employment From</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} >To</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} >Reason of Separation(**)</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} >Gross Compensation Income(Present Employer)</Table.HeaderCell>

                                {/* HEADER ONLY FOR PRESENT EMPLOYER */}
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' colspan={5}>N O N - T A X A B L E</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' colspan={4}>T A X A B L E</Table.HeaderCell>

                                {/* PREVIOUS EMPLOYER (8), (9), (10a) , (10b), (11), 12a=(12f+12j) */}
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} >Taxpayer Identification Number(TIN)</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} >Employment Status(*)</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} >Period of Employment From</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} >To</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} >Reason of Separation, if applicable(**)</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} >Gross Compensation Previous Employer</Table.HeaderCell>

                                {/* HEADER ONLY FOR PREVIOUS EMPLOYER */}
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' colspan={5}>N O N - T A X A B L E</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' colspan={3}>T A X A B L E</Table.HeaderCell>

                                {/* 12j=(12g+12h+12i), 13=(7j+12j) , (14) ,(15a), (15b), 16a=14-(15a+15b), 16b=(15a+15b)-14 , 17=(15b+16a) OR (15b-16b) , (18) */}
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} >Total Taxable Compensation(Previous employer)</Table.HeaderCell>
                            </Table.Row>

                            <Table.Row>
                                {/* (7b), (7c) , (7d) , (7e) , 7f=(7b+7c+7d+7e)  */}
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >13th Month Pay & Other Benefits</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >De Minimis Benefits</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >SSS, GSIS, PHIC & Pagibig Contributions and Union Dues(Employees Share Only)</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >Salaries (P250k & Below) & Other Forms of Compensation</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >Total Non-Taxable/Exempt Compensation Income(present employer)</Table.HeaderCell>

                                {/* (7g) , (7h) , (7i) , 7j=(7g+7h+7i) */}
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >Basic Salary(Net of SSS, GSIS, PHIC, HDMF Contri & Union Dues)</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >13th Month Pay & Other Benefits(In Excess of Threshold)</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >Salaries & Other Forms of Compensation</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >Total Taxable Compensation(present employer)</Table.HeaderCell>

                                {/* (12b) , (12c), (12d), (12e), 12f=(12b+12c+12d+12e) */}
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >13th Month Pay & Other Benefits</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >De Minimis Benefits</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >SSS, GSIS, PHIC & Pagibig Contributions and Union Dues(Employees Share Only)</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >Salaries (P250k & Below) & Other Forms of Compensation</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >Total Non-Taxable/Exempt Compensation(Previous employer)</Table.HeaderCell>
                                
                                {/* (12g), (12h), (12i) */}
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >Basic Salary(Net of SSS, GSIS, PHIC, HDMF Contri & Union Dues)</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >13th Month Pay & Other Benefits</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >Salaries & Other Forms of Compensation</Table.HeaderCell>
                            </Table.Row>

                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(1)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(2a)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(2b)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(2c)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(3)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(4)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(5a)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(5b)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(6)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >7a = (7f+7j)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(7b)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(7c)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(7d)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(7e)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >7f=(7b+7c+7d+7e)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(7g)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(7h)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(7i)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >7j=(7g+7h+7i)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(8)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(9)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(10a)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(10b)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(11)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >12a=(12f+12j)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(12b)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(12c)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(12d)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(12e)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >12f=(12b+12c+12d+12e)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(12g)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(12h)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(12i)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >12j=(12g+12h+12i)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >13=(7j+12j)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(14)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(15a)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(15b)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >16a=14-(15a+15b)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >16b=(15a+15b)-14</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >17=(15b+16a) OR (15b-16b)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(18)</Table.Cell>
                                {/* <Table.Row>
                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={4} >SEQ NO</Table.HeaderCell>
     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={4} >TIN</Table.HeaderCell>
     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' colspan={3}>NAME OF EMPLOYEES</Table.HeaderCell>
     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' colspan={15}>(4) GROSS COMPENSATION INCOME</Table.HeaderCell>
     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' colspan={3}>PRESENT EMPLOYER</Table.HeaderCell>
     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={4}>Total Compensation (Present)</Table.HeaderCell>
     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={4}>Total Taxable (Previous &amp; Present Employers)</Table.HeaderCell>
     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} colspan={2}>EXEMPTION</Table.HeaderCell>
     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={4}>Premium Paid on Health and/or Hospital Insurance</Table.HeaderCell>
     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={4}>Net Taxable Compensation Income</Table.HeaderCell>
     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={4}>TAX DUE (JAN.-DEC.)</Table.HeaderCell>
     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' colspan={2}>TAX WITHHELD</Table.HeaderCell>
     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' colspan={2}>YEAR-END ADJUSTMENT (11a or 11b)</Table.HeaderCell>
     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={4}>AMOUNT OF TAX WITHHELD AS ADJUSTED (To be reflected in BIR Form No. 2316 issued by the employer)</Table.HeaderCell>
 </Table.Row>

 <Table.Row>
     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={3} >Last Name</Table.HeaderCell>
     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={3} >First Name</Table.HeaderCell>
     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={3} >Middle Name</Table.HeaderCell>
     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={3} >Gross Compensation Income</Table.HeaderCell>
     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' colspan={9}>PREVIOUS EMPLOYER</Table.HeaderCell>
     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' colspan={5}>PRESENT EMPLOYER</Table.HeaderCell>
     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' colspan={3}>TAXABLE</Table.HeaderCell>
     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' colspan={2}>(JAN.-NOV.)</Table.HeaderCell>
     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={3}>AMOUNT WITHHELD &amp; PAID FOR IN DECEMBER</Table.HeaderCell>
     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={3}>OVER WITHHELD TAX REFUNDED TO EMPLOYEE</Table.HeaderCell>
 </Table.Row>

 <Table.Row>
     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' colspan={5}>NON-TAXABLE</Table.HeaderCell>
     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' colspan={4}>TAXABLE</Table.HeaderCell>
     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' colspan={5}>NON-TAXABLE</Table.HeaderCell>
     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2}>Basic Salary</Table.HeaderCell>
     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2}>13th Month Pay &amp; Other Benefits</Table.HeaderCell>
     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2}>Salaries &amp; Other Forms of Compensation</Table.HeaderCell>
     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2}>Code</Table.HeaderCell>
     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2}>Amount</Table.HeaderCell>
     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2}>PREVIOUS EMPLOYER</Table.HeaderCell>
     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2}>PRESENT EMPLOYER</Table.HeaderCell>
 </Table.Row>

 <Table.Row>
     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' >13th Month Pay &amp; Other Benefits</Table.HeaderCell>
     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center'>Deminimis Benefits</Table.HeaderCell>
     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center'>SSS,GSIS,PHIC &amp; Pag-ibig Contributions and Union Dues</Table.HeaderCell>
     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center'>Salaries &amp; Other Forms of Compensation</Table.HeaderCell>
     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center'>Total Non-Taxable/Exempt Compensation Income (Previous)</Table.HeaderCell>

     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center'>Basic Salary</Table.HeaderCell>
     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center'>13th Month Pay &amp; Other Benefits</Table.HeaderCell>
     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center'>Salaries &amp; Other Forms of Compensation</Table.HeaderCell>
     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center'>Total Taxable (Previous Employer)</Table.HeaderCell>

     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center'>13th Month Pay &amp; Other Benefits</Table.HeaderCell>
     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center'>Deminimis Benefits</Table.HeaderCell>
     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center'>SSS,GSIS,PHIC &amp; Pag-ibig Contributions and Union Dues</Table.HeaderCell>
     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center'>Salaries &amp; Other Forms of Compensation</Table.HeaderCell>
     <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center'>Total Non-Taxable/Exempt Compensation Income (Previous)</Table.HeaderCell>
 </Table.Row>

 <Table.Row>
     <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(1)</Table.Cell>
     <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(2a)</Table.Cell>
     <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(2b)</Table.Cell>
     <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(2c)</Table.Cell>
     <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >3(c)</Table.Cell>
     <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >4(a)</Table.Cell>
     <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >4(b)</Table.Cell>
     <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >4(c)</Table.Cell>
     <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >4(d)</Table.Cell>
     <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >4(e)</Table.Cell>
     <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >4(f)</Table.Cell>
     <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >4(g)</Table.Cell>
     <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >4(h)</Table.Cell>
     <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >4(i)</Table.Cell>
     <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >4(j)=4(g)+4(h)+4(i)</Table.Cell>
     <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >4(k)</Table.Cell>
     <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >4(l)</Table.Cell>
     <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >4(m)</Table.Cell>
     <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >4(n)</Table.Cell>
     <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >4(o)</Table.Cell>
     <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >4(p)</Table.Cell>
     <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >4(q)</Table.Cell>
     <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >4(r)</Table.Cell>
     <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(4s=4p+4q+4r)</Table.Cell>
     <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(4t=4j+4s)</Table.Cell>
     <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >5(a)</Table.Cell>
     <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >5(b)</Table.Cell>
     <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(6)</Table.Cell>
     <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(7)</Table.Cell>
     <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(8)</Table.Cell>
     <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >9(a)</Table.Cell>
     <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >9(b)</Table.Cell>
     <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(10a)=(8)-(9a+9b)</Table.Cell>
     <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(10b)=(9a+9b)-(8)</Table.Cell>
     <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(11)=(9b+10a) or (9b-10b)</Table.Cell>
 </Table.Row> */}

                                {this.state.alphalistData.map(x =>
                                    <Table.Row>
                                        <Table.Cell> {x.SequenceNumber} </Table.Cell> {/* 1 */}
                                        <Table.Cell> {x.LastName} </Table.Cell> {/* 2a */}
                                        <Table.Cell> {x.FirstName} </Table.Cell> {/* 2b */}
                                        <Table.Cell> {x.MiddleName} </Table.Cell> {/* 2c */}
                                        <Table.Cell>  </Table.Cell> {/* Nationality */} {/* 3 */}
                                        <Table.Cell>  </Table.Cell> {/* Employment Status */} {/* 4 */}
                                        <Table.Cell></Table.Cell> {/* Employment From */} {/* 5a */}
                                        <Table.Cell></Table.Cell> {/* To */} {/* 5b */}
                                        <Table.Cell>  </Table.Cell> {/* Reason Separation */} {/* 6 */}
                                        <Table.Cell> {parseFloat(x.GrossCompensationIncome).toLocaleString()} </Table.Cell> {/* 7a = (7f + 7j) */}

                                        {/* N  O  N    -   T  A  X  A  B  L  E */}
                                        <Table.Cell> {parseFloat(x.Pay13thOtherNT).toLocaleString()} </Table.Cell> {/* 7b */}
                                        <Table.Cell> {parseFloat(x.DeMinimis).toLocaleString()} </Table.Cell> {/* 7c */}
                                        <Table.Cell> {parseFloat(x.GovContri).toLocaleString()} </Table.Cell> {/* 7d */}
                                        <Table.Cell> {parseFloat(x.SalOtherCompNT).toLocaleString()} </Table.Cell> {/* 7e */}
                                        <Table.Cell> {parseFloat(x.TotalCompIncomeNT).toLocaleString()} </Table.Cell> {/* 7f=(7b+7c+7d+7e) */}

                                        {/* T  A  X  A  B  L  E */}
                                        <Table.Cell> {parseFloat(x.BasicNet).toLocaleString()} </Table.Cell> {/* 7g */}
                                        <Table.Cell> {parseFloat(x.Pay13thOtherT).toLocaleString()} </Table.Cell> {/* 7h */}
                                        <Table.Cell> {parseFloat(x.SalOtherCompT).toLocaleString()} </Table.Cell> {/* 7i */}
                                        <Table.Cell> {parseFloat(x.TotalCompIncomeT).toLocaleString()} </Table.Cell> {/* 7j=(7g+7h+7i) */}

                                        {/* PREVIOUS EMPLOYER */}
                                        <Table.Cell> {x.Tin} </Table.Cell> {/* 8 */}
                                        <Table.Cell>  </Table.Cell> {/* Employment Status */} {/* 9 */}
                                        <Table.Cell>  </Table.Cell> {/* Period of Employment From */} {/* 10a */}
                                        <Table.Cell>  </Table.Cell> {/* To */} {/* 10b */}
                                        <Table.Cell>  </Table.Cell> {/* Reason Separation, if applicable */} {/* 11 */}
                                        <Table.Cell>{x.GrossCompensationIncomePE} </Table.Cell> {/* Gross Compensation Previous Employer */} {/* 12a=(12f+12j) */}

                                        {/* NON-TAXABLE PREVIOUS EMPLOYER */}
                                        <Table.Cell> {parseFloat(x.Pay13thOtherNTPE).toLocaleString()} </Table.Cell> {/* 12b */}
                                        <Table.Cell> {parseFloat(x.DeMinimisPE).toLocaleString()} </Table.Cell> {/* 12c */}
                                        <Table.Cell> {parseFloat(x.GovContriPE).toLocaleString()} </Table.Cell> {/* 12d */}
                                        <Table.Cell> {parseFloat(x.SalOtherCompNTPE).toLocaleString()} </Table.Cell> {/* 12e */}
                                        <Table.Cell> {parseFloat(x.TotalCompIncomeNTPE).toLocaleString()} </Table.Cell> {/* 12f=(12b+12c+12d+12e) */}

                                        {/* TAXABLE PREVIOUS EMPLOYER */}
                                        <Table.Cell> {parseFloat(x.BasicSalaryTPE).toLocaleString()} </Table.Cell> {/* (12g) */}
                                        <Table.Cell> {parseFloat(x.Pay13thOtherTPE).toLocaleString()} </Table.Cell> {/* (12h) */}
                                        <Table.Cell> {parseFloat(x.SalOtherCompTPE).toLocaleString()} </Table.Cell> {/* (12i) */}
                                        <Table.Cell> {parseFloat(x.TotalCompIncomeTPE).toLocaleString()} </Table.Cell> {/* 12j=(12g+12h+12i) */}
                                        
                                        {/* SUMMATION */}
                                        <Table.Cell> {parseFloat(x.TotalTaxable).toLocaleString()} </Table.Cell> {/* 13=(7j+12j) */}
                                        <Table.Cell> {parseFloat(x.TaxDue).toLocaleString()} </Table.Cell> {/* (14) */}
                                        <Table.Cell> {parseFloat(x.TaxWithHeldPE).toLocaleString()} </Table.Cell> {/* (15a) */}
                                        <Table.Cell> {parseFloat(x.TaxWithHeld).toLocaleString()} </Table.Cell> {/* (15b)  */}
                                        <Table.Cell> {parseFloat(x.YearEndAdjustment).toLocaleString()}</Table.Cell> {/* Tax withheld Present employer */} {/* 16a=14-(15a+15b)  */}
                                        <Table.Cell> {parseFloat(x.OverWithHeld).toLocaleString()}</Table.Cell> {/* Y E A R - E N D   A D J U S T M E N T (16a or 16b) */} {/* 16b=(15a+15b)-14  */}
                                        <Table.Cell> {parseFloat(x.TaxWithHeldAdjusted).toLocaleString()} </Table.Cell> {/* 17=(15b+16a) OR (15b-16b)  */}
                                        <Table.Cell>  </Table.Cell> {/* Substitute Filling */} {/* 16a=14-(15a+15b)  */}


                                     {/* <Table.Cell> {x.ExemptTaxCode} </Table.Cell> */}
                                     {/* <Table.Cell> {parseFloat(x.ExemptTaxAmount).toFixed(2)} </Table.Cell> */}
                                     {/* <Table.Cell> {parseFloat(x.PemiumPaidHealthHospInsure).toFixed(2)} </Table.Cell> */}
                                     {/* <Table.Cell> {parseFloat(x.TaxWithHeldNov).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.TaxWithHeldDec).toFixed(2)} </Table.Cell> */}
                                    </Table.Row>
                                )}
                            </Table>}

                            {(selectedFilter === 3) && <Table Fixed> {/*Minimum Wage Earner*/}
                                {/* <Table.Row>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={5}>SEQ NO</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={5}>TIN</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' colspan={3}>NAME OF EMPLOYEES</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={5}>Region No. Where Assigned</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' colspan={33}>GROSS COMPENSATION INCOME</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} colspan={2}>EXEMPTION</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={5}>PremiumPaid on Health and/or Hospital Insurance</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={5}>Net Taxable Compensation Income</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={5}>Tax Due (JAN.-DEC.)</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} colspan={2}>TAX WITHHELD (JAN.-NOV.)</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' colspan={2}>YEAR-END ADJUSTMENT (11a or 11b)</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={5}>AMOUNT OF TAX WITHHELD AS ADJUSTED (To be reflected in BIR Form No. 2316 issued by the employer)</Table.HeaderCell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={4} >Last Name</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={4} >First Name</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={4} >Middle Name</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' colspan={14}>PREVIOUS EMPLOYER</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' colspan={19}>PRESENT EMPLOYER</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={4}>AMOUNT WITHHELD AND PAID FOR IN DECEMBER </Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={4}>OVER WITHHELD TAX REFUND TO EMPLOYEE </Table.HeaderCell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' colspan={11}>NON-TAXABLE</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' colspan={3}>TAXABLE</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' colspan={15}>NON-TAXABLE</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' colspan={2}>TAXABLE</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={3}>Total Compensation Present</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={3}>Total Compensation Income (Previous &amp; Present Employers)</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={3} > Code</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={3} > Amount</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={3}>PREVIOUS EMPLOYER</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={3}>PRESENT EMPLOYER</Table.HeaderCell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} > Gross Compensation Income Previous</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} > Basic/SMW</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} > Holiday Pay</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} > Overtime Pay</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} > Night Shift Differential</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} >Hazard Pay</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} >13th Month Pay &amp; Other Benefits</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} >De Minimis Benefits</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} >SSS,GSIS,PHIC, &amp; Pag-ibig Contributions and Union Dues</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} >Salaries &amp; Other Forms of Compensation</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} >Total Non-Taxable/Exempt Compensation Income</Table.HeaderCell>

                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} >13th Month Pay &amp; Other Benefits</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} >Salaries &amp; Other Forms of Compensation</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} >Total Taxable (Previous Employer)</Table.HeaderCell>

                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' colspan={2}> Inclusive Date of Employment</Table.HeaderCell>

                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} > Gross Compensation Income</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} > Basic SMW Per Day</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} > Basic SMW Per Month</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} > Basic SMW Per Year</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} > Factor Used (No. of Days/Years)</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} > Holiday Pay</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} > Overtime Pay</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} > Night Shift Differential</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} > Hazard Pay</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} > 13th Month Pay &amp; Other Benefits</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} > De Minimis Benefits</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} > SSS,GSIS.PHIC. &amp; Pag-ibig Contributions and Union Dues</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} > Salaries &amp; Other Forms of Compensation</Table.HeaderCell>

                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} > 13th Month Pay &amp; Other Benefits</Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} > Salaries &amp; Other Forms of Compensation</Table.HeaderCell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' > From </Table.HeaderCell>
                                    <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' > To </Table.HeaderCell>
                                </Table.Row>

                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(1)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(2)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >3(a)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >3(b)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >3(c)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(4)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >5(a)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >5(b)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >5(c)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >5(d)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >5(e)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >5(f)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >5(g)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >5(h)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >5(i)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >5(j)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >5(k)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >5(l)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >5(m)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(5n=5l+5m)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >5(o)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >5(p)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >5(q)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >5(r)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >5(s)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >5(t)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >5(u)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >5(v)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >5(w)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >5(x)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >5(y)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >5(z)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >5(aa)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >5(ab)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >5(ac)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >5(ad)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >5(ae)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >5(af)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >5(ag)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >6(a)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >6(b)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(7)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(8)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(9)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >10(a)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >10(b)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(11a)=(9)-(10a+10b)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(11b)=(10a+10b)-(9)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(12)=(10b+11a) or (10b-11b)</Table.Cell> */}

                            <Table.Row>
                                {/* (1) */}
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={3}>SEQ NO</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' colspan={3}>NAME OF EMPLOYEES</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' colspan={25}>P R E S E N T &nbsp;&nbsp; E M P L O Y E R</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' colspan={17}>P R E V I O U S &nbsp;&nbsp; E M P L O Y E R</Table.HeaderCell>
                            
                                {/*12n=(12l+12m), (13), (14), (15a), (15b), (16a), (16b), (17) */}
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={3} >Total Taxable Compensation Income(Present and Previous employer)</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={3} >Tax Due</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={3} >Tax Withheld Previous Employer</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={3} >Tax Withheld Present Employer</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={3} >Year-end Adjustment (16a or 16b) AMT Withheld & Paid for In December or Last Salary</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={3} >Over Withheld Tax refunded to Employee</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={3} >Amount of Tax withheld as Adjusted</Table.HeaderCell>
                            </Table.Row>

                            <Table.Row>
                                {/* (2a)(2b)(2c),  */}
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} >Last Name</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} >First Name</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} >Middle Name</Table.HeaderCell>

                                {/* (3), (4), (5a), (5b), (6), 7a=(7f+7j)  */}
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} >Current Employment Status (*)</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} >Period of Where Assigned</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} >Employment From</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} >To</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} >Reason of Separation(**)</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} >Gross Compensation Income(Present Employer)</Table.HeaderCell>

                                {/* HEADER ONLY FOR PRESENT EMPLOYER */}
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' colspan={14}>N O N - T A X A B L E</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' colspan={5}>T A X A B L E</Table.HeaderCell>

                                {/* PREVIOUS EMPLOYER (10a),(10b),(11),12a=(12k+12n) */}
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} >Period of Employment From</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} >To</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} >Reason of Separation, if applicable(**)</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} >Gross Compensation Previous Employer</Table.HeaderCell>

                                {/* HEADER ONLY FOR PREVIOUS EMPLOYER */}
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' colspan={10}>N O N - T A X A B L E</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' colspan={2}>T A X A B L E</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={2} >Total Taxable Compensation(Previous employer)</Table.HeaderCell>
                            </Table.Row>

                            <Table.Row>
                                {/* (7b), (7c),(7d),(7e),(7f),(7g),(7h),(7i),(7j),(7k),(7l),(7m),(7n),(7o) */}
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >Basic SMW per day</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >Basic SMW per month</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >Basic SMW per year</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >Factor used(No of days/year)</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >Basic/SMW</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >Holiday Pay</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >Overtime Pay</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >Night Shift Differential</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >Hazard Pay</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >13th Month Pay & Other Benefits</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >De Minimis Benefits</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >SSS, GSIS, PHIC & Pag-ibig Contributions and Union Dues</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >Salaries & Other Forms of Compensation</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >Total Non-Taxable/Exempt Compensation Income</Table.HeaderCell>

                                {/* (7p) , (7q) , 7r=(7p+7q) , (8), (9)  */}
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >13th Month Pay& Other Benefits</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >Salaries & Other Forms of Compensation</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >Total Taxable(present employer)</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >Taxpayer Identification Number</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >Employment Status</Table.HeaderCell>
                                
                                {/* (12b), (12c), (12d), (12e), (12f), (12g), (12h), (12i), (12j), 12k=(sum of 12b to 12j) */}
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >Non-taxable Basic/SMW(Actual Net of SSS,GSIS, PHIC)</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >Holiday Pay</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >Overtime Pay</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >Night Shift Differential</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >Hazard Pay</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >13th Month Pay & Other Benefits</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >De Minimis Benefits</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >SSS,GSIS,PHIC & Pag-ibig Contributions and Union Dues</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >Salaries & Other Forms of Compensation</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >Total Non-taxable/Exempt Compensation(Previous Employer)</Table.HeaderCell>
                                
                                {/* (12l), (12m) */}
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >13th Month Pay & Other Benefits</Table.HeaderCell>
                                <Table.HeaderCell style={borderStyle} verticalAlign='top' textAlign='center' rowspan={1} >Salaries & Other Forms of Compensation</Table.HeaderCell>
                            </Table.Row>

                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(1)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(2a)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(2b)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(2c)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(3)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(4)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(5a)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(5b)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(6)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >7a=(7o+7r)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(7b)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(7c)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(7d)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(7e)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(7f)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(7g)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(7h)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(7i)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(7j)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(7k)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(7l)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(7m)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(7n)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(7o)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(7p)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(7q)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >7r=(7p+7q)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(8)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(9)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(10a)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(10b)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(11)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >12a=(12k+12n)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(12b)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(12c)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(12d)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(12e)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(12f)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(12g)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(12h)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(12i)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(12j)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >12k=(sum of 12b to 12j)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(12l)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(12m)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >12n=(12l+12m)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(13)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(14)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(15a)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(15b)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(16a)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(16b)</Table.Cell>
                                <Table.Cell style={arrangementBorderStyle} verticalAlign='bottom' textAlign='center' >(17)</Table.Cell>

                                {this.state.alphalistData.map(x =>
                                    <Table.Row>
                                        <Table.Cell> {x.SequenceNumber} </Table.Cell> 
                                        <Table.Cell> {x.LastName} </Table.Cell>
                                        <Table.Cell> {x.FirstName} </Table.Cell>
                                        <Table.Cell> {x.MiddleName} </Table.Cell>
                                        <Table.Cell>  </Table.Cell>  {/* Current Employment Status */}
                                        <Table.Cell>  </Table.Cell>  {/* Period of where assigned */}
                                        <Table.Cell>  </Table.Cell>  {/* Employment From */}
                                        <Table.Cell>  </Table.Cell>  {/* To */}
                                        <Table.Cell>  </Table.Cell>  {/* Reason of Separation */}
                                        
                                        <Table.Cell> {parseFloat(x.GrossCompensationIncome).toFixed(2)} </Table.Cell>

                                        {/* NON-TAXABLE PRESENT EMPLOYER */}
                                        <Table.Cell>  </Table.Cell>  {/* Basic/SMW Per day*/}
                                        <Table.Cell>  </Table.Cell>  {/* Basic SMW per month */}
                                        <Table.Cell>  </Table.Cell>  {/* Factor used (No of days/year) */}
                                        <Table.Cell> {parseFloat(x.BasicSalaryNT).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.HolidayPay).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.OverTimePay).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.NightShiftDifferential).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.HazardPay).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.Pay13thOtherNT).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.DeMinimis).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.GovContri).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.SalOtherCompNT).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.TotalCompIncomeNT).toFixed(2)} </Table.Cell>

                                        {/* TAXABLE PRESENT EMPLOYER */}
                                        <Table.Cell> {parseFloat(x.Pay13thOtherTPE).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.SalOtherCompTPE).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.TotalCompIncomeTPE).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {x.Tin} </Table.Cell>
                                        <Table.Cell>  </Table.Cell>  {/* Employment Status */}

                                        {/* PREVIOUS EMPLOYER */}
                                        <Table.Cell>  </Table.Cell>  {/* Period of employment from */}
                                        <Table.Cell>  </Table.Cell>  {/* To */}
                                        <Table.Cell>  </Table.Cell>  {/* Reason of Separation, if applicable(*) */}
                                        <Table.Cell> {parseFloat(x.GrossCompensationIncomePE).toFixed(2)} </Table.Cell>  {/* Gross Compensation Previous Employer */}

                                        {/* NON-TAXABLE PREVIOUS EMPLOYER */}
                                        <Table.Cell>  </Table.Cell>  {/* Basic/SMW Actual net of SSS, GSIS, PHIC */}
                                        <Table.Cell> {parseFloat(x.HolidayPayPE).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.OverTimePayPE).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.NightShiftDifferentialPE).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.HazardPayPE).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.Pay13thOtherNTPE).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.DeMinimisPE).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.GovContriPE).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.SalOtherCompNTPE).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.TotalCompIncomeTPE).toFixed(2)} </Table.Cell>

                                        {/* TAXABLE PREVIOUS EMPLOYER */}
                                        <Table.Cell>  </Table.Cell>  {/* 13th Month Pay & Other Benefits */}
                                        <Table.Cell>  </Table.Cell>  {/* Salaries & other forms of compensation */}
                                        <Table.Cell> {parseFloat(x.TotalCompIncomeT + x.TotalCompIncomeTPE).toFixed(2)} </Table.Cell>
                                        <Table.Cell>  </Table.Cell>  {/* Total Taxable compensation income (present and previous employer) */}
                                        <Table.Cell> {parseFloat(x.TaxDue).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.TaxWithHeldPE).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.TaxWithHeld).toFixed(2)} </Table.Cell>
                                        <Table.Cell>  </Table.Cell>  {/* Tax Withheld Present Employer */}
                                        <Table.Cell> {parseFloat(x.TaxWithHeldAdjusted).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.OverWithHeld).toFixed(2)} </Table.Cell>
                                        <Table.Cell>  </Table.Cell>  {/* Ammount of Tax Withheld  as adjusted */}

                                        {/* <Table.Cell> {x.Tin} </Table.Cell> */}
                                        {/* <Table.Cell> {x.RegionNoWhereAssigned === -1 ? "" : x.RegionNoWhereAssigned} </Table.Cell> */}
                                        {/* <Table.Cell> {x.DateHired} </Table.Cell>
                                        <Table.Cell> {x.DateResigned} </Table.Cell> */}


                                        {/* <Table.Cell> {parseFloat(x.BasicPerDay).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.BasicPerMonth).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.BasicPerYear).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.FactorUsed).toFixed(2)} </Table.Cell> */}

                                        {/*
                                        <Table.Cell> {parseFloat(x.Pay13thOtherT).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.SalOtherCompT).toFixed(2)} </Table.Cell> 
                                        <Table.Cell> {x.ExemptTaxCode} </Table.Cell>

                                        <Table.Cell> {parseFloat(x.ExemptTaxAmount).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.PemiumPaidHealthHospInsure).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.NetTaxableCompIncome).toFixed(2)} </Table.Cell>
                                        <Table.Cell> {parseFloat(x.TaxWithHeldDec).toFixed(2)} </Table.Cell>

                                        */}


                                    </Table.Row>
                                )}
                            </Table>}
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default Alphalist;