import React, { Component } from 'react';
// import { Checkbox, Radio, Modal, Menu } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import Select from 'react-select';
import { fnSearchEmployeeFilter, customStyles } from '../utils';
import { ajaxPost } from '../ajax';
import { TableCell } from 'semantic-ui-react';
//import CustomSelect from './CustomSelect';

function employeeSearchlist(list) {
    var employees = [];
    if (list != null) {
        list.map(x => {
            var fullname = x.LastName + ", " + x.FirstName + " " + x.MiddleName;

            return (
                employees.push({ label: fullname, value: x._id })
            )
        })
    }
    return employees;
}
const errorStyles = {
    control: (base, state) => ({
        ...base,
        color: "#DF4141",
        borderColor: "#DF4141",
        minHeight: 36,
        maxHeight: 95,
        overflowY: 'auto'
    })
}
class SelectFilterEmployees extends Component {
    state = {
        Employeelist: [],
        EmployeeIds: [],
        searchQuery: '',
        errorStyle: false,
        isMulti:true,
        includeDeleted:false,
        CompanyIds: [],
        DivisionIds: [],
        DepartmentIds: [],
        PositionIds: [],
        LocationIds: [],
        inactiveOnly:false,
    }
    constructor(props) {
        super(props);
        this.state = {
            loadedIds: this.props.Ids,
            EmployeeIds: [],
            isLoading: false,
            includeDeleted: this.props.includeDeleted !== undefined ? this.props.includeDeleted : false,
            errorStyle: this.props.errorStyle !== undefined ? this.props.errorStyle : false,
            isMulti: this.props.isMulti !== undefined ? this.props.isMulti :true,
            CompanyIds: props.companyIds != undefined ? props.companyIds : [],
            DivisionIds: props.divisionIds != undefined ? props.divisionIds : [],
            DepartmentIds: props.departmentIds != undefined ? props.departmentIds : [],
            PositionIds: props.positionIds != undefined ? props.positionIds : [],
            LocationIds: props.locationIds != undefined ? props.locationIds : [],
            inactiveOnly: props.inactiveOnly !== undefined ? props.inactiveOnly : false ,
        }
    }
    componentWillMount() {
        this.setState({ isLoading: true });
        this.doSearch("", this.state.loadedIds);

        this.searchTimer = null;
    }
    handleSearchChange = (text) => {
        clearTimeout(this.searchTimer);

        if(this.state.searchQuery !== text){
            this.setState({ searchQuery: text, includeDeleted: this.props.includeDeleted !== undefined ? this.props.includeDeleted : false })
            this.searchTimer = setTimeout(this.doSearch, 400, text);
        }
    }
    doSearch = (str, initialIds) => {
        const { EmployeeIds, Employeelist } = this.state;
        var selectedEmpIds = [];
         if (EmployeeIds !== null && EmployeeIds !== undefined && Employeelist !== undefined)
            selectedEmpIds = Employeelist.filter(x => EmployeeIds.map(x => x.value).includes(x._id)).map(x => x._id);
            

        this.setState({ searchQuery: str, isLoading: true },
            () =>{
                ajaxPost({
                    url: "api/Employee/searchFilteredEmployee",
                    data: {
                        "Search": str,
                        "includeDeleted": this.state.includeDeleted,
                        "startingIndex": 0,
                        "itemCount": 10,
                        "excludedEmployeeIds": selectedEmpIds,
                        "employeeIds": initialIds !== undefined && initialIds.length > 0? initialIds: null,
                        "CompanyIDs": this.state.CompanyIds,
                        "DivisionIDs": this.state.DivisionIds,
                        "DepartmentIDs": this.state.DepartmentIds,
                        "PositionIDs": this.state.PositionIds,
                        "CityIDs": this.state.LocationIds,
                        "inactiveOnly": this.state.inactiveOnly,
                    },
                    onSuccess: data => {
                        this.setState({ Employeelist: data.content, isLoading: false });
                        if(initialIds !== undefined && initialIds.length > 0)
                            this.setState({EmployeeIds: employeeSearchlist(data.content)}, () => this.doSearch(""))
                    },
                    finally: () => { }
                })
            }
        )
    }

    handleSearchEmployeeChange = (value) => {
        var ids = [];
        var values = [];
        if(this.state.isMulti){
            if (value != null && !(value === "" || value.length === 0))
                ids = value.map(x => x.value);
            values = value;
        }else{
            ids.push(value.value);
            values.push(value);
        }

        this.setState({ EmployeeIds: values, searchQuery: "" }, () => this.doSearch(""));
        this.props.update(ids);
    }

    render() {
        // var newStyle = this.props.style;
        // if(newStyle === undefined)
        //     newStyle = {};

        return (
            <div style={{width: this.props.width}}>
                <Select placeholder='Employees'
                    onChange={this.handleSearchEmployeeChange.bind(this)}
                    options={employeeSearchlist(this.state.Employeelist)}
                    onInputChange={this.handleSearchChange.bind(this)}
                    styles={this.props.errorStyle ? errorStyles : customStyles}
                    value={this.state.isMulti ? this.state.EmployeeIds : this.state.EmployeeIds[0]}
                    isMulti={this.state.isMulti}
                    isDisabled={this.props.disabled === undefined ? false : this.props.disabled}
                    isLoading={this.state.isLoading}
                    searchQuery={this.props.searchQuery}
                />
            </div>
            
        )
    }

}
SelectFilterEmployees.defaultProps = {
    EmployeeIds: [],
    errorStyle: false
}
export default SelectFilterEmployees