import React, { Component } from 'react';
import 'semantic-ui-css/semantic.min.css';
import { Loader, Icon, Container } from 'semantic-ui-react';
// import "react-table/react-table.css";
import { ajaxPost } from '../../ajax';
import DataGrid from 'react-data-grid';
import { TimeDisplayFormat } from '../../utils';

import modStyles from './DTRList.module.css';

function FormattedValue(value) {
    if (value == null || value == 0)
        return "";

    return (value / 60).toFixed(2);
}
function FormattedToHHMM(minutes) {
    if (minutes === 0)
        return "";

    var hours = parseInt((Math.abs((minutes) / 60)));
    var minute = parseInt(minutes) % 60;
    return hours.toString().padStart(2, "0") + ":" + minute.toString().padStart(2, "0");
}
const WorkScheduleActions = [
    {
        icon: <Icon name='help' />,
        callback: () => {
            alert("link to profile schedule");
        }
    }
];
const WorkScheduleFormatter = ({ value, row }) => {
    return (<Container><Icon className="linkify" onClick={() => {

    }}></Icon> {value}</Container>);

};
function getCellActions(column, row) {
    const cellActions = {
        WorkSchedule: WorkScheduleActions
    };
    return row.WorkSchedule != "" ? cellActions[column.key] : null;
}
class DTRList extends Component {
    state = {
        Parameters: {
            CutoffId: "-1",
            needle: "",
            startingIndex: 0,
            itemCount: 10,
            totalPages: 1,
            advanceFilter: {
                companyIds: [],
                divisionIds: [],
                departmentIds: [],
                locationIds: [],
                costcenterIds:[],
                positionIds: []
            },
        },
        initialLoad: true,
        Loading: false,
        ActivePage: 1,
        PageSize: 100,
        DTRDetails: [],
        hiddenColumns: [],
        gridHeight: window.innerHeight - 320,
    }
    constructor(props) {
        super(props);
        
        this.state.hiddenColumns = this.props.hiddenColumns;
        this.state.Parameters = this.props.Parameters;
        this.state.initialLoad = this.props.initialLoad;
        this.state.DTRDetails = this.props.DTRDetails;
        this._columns = [
            { key: 'EmployeeNumber', name: 'Employee Number', width: 150, locked: true, resizable: true },
            { key: 'Employee', name: 'Employee', width: 200, resizable: true, locked: true, },
            { key: 'Date', name: 'Date', width: 150, locked: true, resizable: true },            
            { key: 'WorkSchedule', name: 'Work Schedule', width: 180, locked: false, resizable: true },
            { key: 'BreakSchedule', name: 'Break Schedule', width: 180, resizable: true },
            { key: 'Remarks', name: 'Remarks', width: 200, locked: false, resizable: true },
            { key: 'TimeInOut', name: 'Time In/Out', width: 180, locked: false, resizable: true },
            { key: 'BreakInOut', name: 'Break Out/In', width: 180, resizable: true },
            { key: 'Absent', name: 'Absent', width: 150, resizable: true },
            { key: 'HW', name: 'Reg Hours Work', width: 150 },
            { key: 'Late', name: 'Late', width: 100 },
            { key: 'UT', name: 'UT', width: 100 },
            { key: 'OverBreak', name: 'OverBreak', width: 150 },
            { key: 'OT', name: 'Reg OT', width: 100 },
            { key: 'ND', name: 'ND', width: 100 },
            { key: 'NDOT', name: 'NDOT', width: 100 },
            { key: 'RD', name: 'RD', width: 100 },
            { key: 'RDOT', name: 'RDOT', width: 100 },
            { key: 'RDND', name: 'RDND', width: 100 },
            { key: 'RDNDOT', name: 'RDNDOT', width: 100 },
            { key: 'HD', name: 'HD', width: 100 },
            { key: 'HDOT', name: 'HDOT', width: 100 },
            { key: 'HDND', name: 'HDND', width: 100 },
            { key: 'HDNDOT', name: 'HDNDOT', width: 100 },
            { key: 'HDRD', name: 'HDRD', width: 100 },
            { key: 'HDRDOT', name: 'HDRDOT', width: 100 },
            { key: 'HDRDND', name: 'HDRDND', width: 100 },
            { key: 'HDRDNDOT', name: 'HDRDNDOT', width: 100 },
            { key: 'SD', name: 'SD', width: 100 },
            { key: 'SDOT', name: 'SDOT', width: 100 },
            { key: 'SDND', name: 'SDND', width: 100 },
            { key: 'SDNDOT', name: 'SDNDOT', width: 100 },
            { key: 'SDRD', name: 'SDRD', width: 100 },
            { key: 'SDRDOT', name: 'SDRDOT', width: 100 },
            { key: 'SDRDND', name: 'SDRDND', width: 100 },
            { key: 'SDRDNDOT', name: 'SDRDNDOT', width: 100 },
            { key: 'DH', name: 'DH', width: 100 },
            { key: 'DHOT', name: 'DHOT', width: 100 },
            { key: 'DHND', name: 'DHND', width: 100 },
            { key: 'DHNDOT', name: 'DHNDOT', width: 100 },
            { key: 'DHRD', name: 'DHRD', width: 100 },
            { key: 'DHRDOT', name: 'DHRDOT', width: 100 },
            { key: 'DHRDND', name: 'DHRDND', width: 100 },
            { key: 'DHRDNDOT', name: 'DHRDNDOT', width: 100 },
            { key: 'TotalOT', name: 'Total OT', width: 100 }
        ];
    }
    componentDidUpdate(prevProps) {
        if (prevProps.hiddenColumns !== this.props.hiddenColumns) {
            this.setState({ hiddenColumns: this.props.hiddenColumns });
        }
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize = this.handleResize.bind(this);
        if (this.state.initialLoad === true) {
            var param = this.state.Parameters;
            this.onSearch(param);
        }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }
    componentDidMount() {
        this.handleResize();
    }
    handleResize = () => {

        let headerMargin = 271;
        let footerMargin = 35;
        let gridTop = (document.getElementById("table")) == null ? 50 : (document.getElementById("table")).offsetTop;
        var gridHeight = window.innerHeight - (headerMargin + gridTop + footerMargin);
        this.setState({
            gridHeight,
        });
    }
    rowGetter = (i) => {
        return this.state.DTRDetails[i];
    };

    onSearch = (parameter) => {
        this.setState({ Loading: true });
        this.LoadCutoffDetails(data => {
            this.mapData(data.Content);
            var totalPages = data.Count / this.state.Parameters.itemCount;
            totalPages -= totalPages % 1;
            if (data.Count > totalPages * this.state.Parameters.itemCount) {
                totalPages++;
            }
            var parameters = this.state.Parameters;
            parameters.totalPages = totalPages;
            this.setState({
                Loading: false,
                initialLoad: false,
                Parameters: parameters
            });
            var state = this.state;
            this.props.updateState(state, "DTRList");
        }, parameter);
    }
    componentWillUnmount() {
        this.props.updateState(this.state, "DTRList");
    }
    LoadCutoffDetails = (callback, parameters) => {
        parameters["ViewTab"] = "DTR";
        ajaxPost({
            url: 'api/Cutoff/LoadCutoffDetail',
            data: {
                "Parameters": JSON.stringify(parameters),
            },
            onSuccess: data => {
                callback(data);
            },
            finally: () => { }
        })
    }

    mapData = (cutoffDetails) => {
        var DTRViews = []
        // var rowCount = 0;
        cutoffDetails.forEach(dtr => {
            dtr.DTR.forEach(det => {
                var DTRView =
                {
                    EmployeeNumber: det.Employee.EmployeeNumber,
                    Employee: det.Employee.LastName + ", " + det.Employee.FirstName + " " + det.Employee.MiddleName,
                    Date: det.DTR.FormattedDate,
                    WorkSchedule: det.DTR.FormattedWorkSchedule,
                    BreakSchedule: det.DTR.FormattedBreakSchedule,
                    BreakInOut: det.DTR.FormattedMoreBreaks, //det.DTR.FormattedBreakEntries,
                    TimeInOut: det.DTR.FormattedtimeEntries,
                    Absent: det.DTR.DTRSummary.ABSENT,
                    HW: det.DTR.DTRSummary.OB == 0 ? TimeDisplayFormat(det.DTR.DTRSummary.RH) : TimeDisplayFormat(det.DTR.DTRSummary.RH) + " ( " + TimeDisplayFormat(det.DTR.DTRSummary.OB) + " OB)",
                    Late: TimeDisplayFormat(det.DTR.DTRSummary.LATE),
                    UT: TimeDisplayFormat(det.DTR.DTRSummary.UNDERTIME),
                    OverBreak: TimeDisplayFormat(det.DTR.DTRSummary.OVERBREAK),
                    TotalOT: TimeDisplayFormat(det.DTR.DTRSummary.TotalOT),
                    OT: TimeDisplayFormat(det.DTR.DTRSummary.OT),
                    ND: TimeDisplayFormat(det.DTR.DTRSummary.ND),
                    NDOT: TimeDisplayFormat(det.DTR.DTRSummary.NDOT),
                    RD: TimeDisplayFormat(det.DTR.DTRSummary.RD),
                    RDOT: TimeDisplayFormat(det.DTR.DTRSummary.RDOT),
                    RDND: TimeDisplayFormat(det.DTR.DTRSummary.RDND),
                    RDNDOT: TimeDisplayFormat(det.DTR.DTRSummary.RDNDOT),
                    HD: TimeDisplayFormat(det.DTR.DTRSummary.HD),
                    HDOT: TimeDisplayFormat(det.DTR.DTRSummary.HDOT),
                    HDND: TimeDisplayFormat(det.DTR.DTRSummary.HDND),
                    HDNDOT: TimeDisplayFormat(det.DTR.DTRSummary.HDNDOT),
                    HDRD: TimeDisplayFormat(det.DTR.DTRSummary.HDRD),
                    HDRDOT: TimeDisplayFormat(det.DTR.DTRSummary.HDRDOT),
                    HDRDND: TimeDisplayFormat(det.DTR.DTRSummary.HDRDND),
                    HDRDNDOT: TimeDisplayFormat(det.DTR.DTRSummary.HDRDNDOT),
                    SD: TimeDisplayFormat(det.DTR.DTRSummary.SD),
                    SDOT: TimeDisplayFormat(det.DTR.DTRSummary.SDOT),
                    SDND: TimeDisplayFormat(det.DTR.DTRSummary.SDND),
                    SDNDOT: TimeDisplayFormat(det.DTR.DTRSummary.SDNDOT),
                    SDRD: TimeDisplayFormat(det.DTR.DTRSummary.SDRD),
                    SDRDOT: TimeDisplayFormat(det.DTR.DTRSummary.SDRDOT),
                    SDRDND: TimeDisplayFormat(det.DTR.DTRSummary.SDRDND),
                    SDRDNDOT: TimeDisplayFormat(det.DTR.DTRSummary.SDRDNDOT),
                    DH: TimeDisplayFormat(det.DTR.DTRSummary.DH),
                    DHOT: TimeDisplayFormat(det.DTR.DTRSummary.DHOT),
                    DHND: TimeDisplayFormat(det.DTR.DTRSummary.DHND),
                    DHNDOT: TimeDisplayFormat(det.DTR.DTRSummary.DHNDOT),
                    DHRD: TimeDisplayFormat(det.DTR.DTRSummary.DHRD),
                    DHRDOT: TimeDisplayFormat(det.DTR.DTRSummary.DHRDOT),
                    DHRDND: TimeDisplayFormat(det.DTR.DTRSummary.DHRDND),
                    DHRDNDOT: TimeDisplayFormat(det.DTR.DTRSummary.DHRDNDOT),
                    Remarks: det.DTR.FormattedRemarks
                }
                DTRViews.push(DTRView);
            });
            //total
            var DTRViewTotal =
            {
                EmployeeNumber: "",
                Employee: "",
                Date: "",
                WorkSchedule: "",
                BreakSchedule: "",
                BreakInOut: "",
                // TimeInOut:"Total",
                Absent: dtr.Summary.ABSENT,
                HW: dtr.Summary.OB == 0 ? TimeDisplayFormat(dtr.Summary.RH) : TimeDisplayFormat(dtr.Summary.RH) + " ( " + TimeDisplayFormat(dtr.Summary.OB) + " OB)",
                Late: TimeDisplayFormat(dtr.Summary.LATE) ,
                UT: TimeDisplayFormat(dtr.Summary.UNDERTIME),
                OverBreak: TimeDisplayFormat(dtr.Summary.OVERBREAK),
                TotalOT: TimeDisplayFormat(dtr.Summary.TotalOT),
                OT: TimeDisplayFormat(dtr.Summary.OT),
                ND: TimeDisplayFormat(dtr.Summary.ND),
                NDOT: TimeDisplayFormat(dtr.Summary.NDOT),
                RD: TimeDisplayFormat(dtr.Summary.RD),
                RDOT: TimeDisplayFormat(dtr.Summary.RDOT),
                RDND: TimeDisplayFormat(dtr.Summary.RDND),
                RDNDOT: TimeDisplayFormat(dtr.Summary.RDNDOT),
                HD: TimeDisplayFormat(dtr.Summary.HD),
                HDOT: TimeDisplayFormat(dtr.Summary.HDOT),
                HDND: TimeDisplayFormat(dtr.Summary.HDND),
                HDNDOT: TimeDisplayFormat(dtr.Summary.HDNDOT),
                HDRD: TimeDisplayFormat(dtr.Summary.HDRD),
                HDRDOT: TimeDisplayFormat(dtr.Summary.HDRDOT),
                HDRDND: TimeDisplayFormat(dtr.Summary.HDRDND),
                HDRDNDOT: TimeDisplayFormat(dtr.Summary.HDRDNDOT),
                SD: TimeDisplayFormat(dtr.Summary.SD),
                SDOT: TimeDisplayFormat(dtr.Summary.SDOT),
                SDND: TimeDisplayFormat(dtr.Summary.SDND),
                SDNDOT: TimeDisplayFormat(dtr.Summary.SDNDOT),
                SDRD: TimeDisplayFormat(dtr.Summary.SDRD),
                SDRDOT: TimeDisplayFormat(dtr.Summary.SDRDOT),
                SDRDND: TimeDisplayFormat(dtr.Summary.SDRDND),
                SDRDNDOT: TimeDisplayFormat(dtr.Summary.SDRDNDOT),
                DH: TimeDisplayFormat(dtr.Summary.DH),
                DHOT: TimeDisplayFormat(dtr.Summary.DHOT),
                DHND: TimeDisplayFormat(dtr.Summary.DHND),
                DHNDOT: TimeDisplayFormat(dtr.Summary.DHNDOT),
                DHRD: TimeDisplayFormat(dtr.Summary.DHRD),
                DHRDOT: TimeDisplayFormat(dtr.Summary.DHRDOT),
                DHRDND: TimeDisplayFormat(dtr.Summary.DHRDND),
                DHRDNDOT: TimeDisplayFormat(dtr.Summary.DHRDNDOT),
                Remarks: ""
            }
            DTRViews.push(DTRViewTotal);
        });
        this.setState({ DTRDetails: DTRViews });
    }
    handleChangePage = (event, page) => {
        const startIndex = page.activePage - 1;
        this.setState({ Page: startIndex });
        this.setState({ ActivePage: page.ActivePage });
    }
    ShowDTR = (event) => {
        var param = this.state.Parameters;
        this.LoadCutoffDetails(data => {
            this.mapData(data.Content);
            var totalPages = data.Count / this.state.Parameters.itemCount;
            totalPages -= totalPages % 1;
            if (data.Count > totalPages * this.state.Parameters.itemCount) {
                totalPages++;
            }
            var parameters = this.state.Parameters;
            parameters.totalPages = totalPages;
            this.setState({
                Parameters: parameters
            });
        }, param, 0, 1000);
    }

    render() {
        const columnsToRender = this._columns.filter(column => !this.state.hiddenColumns[column.key]);
        return <div className={modStyles.container}>
                <Loader active={this.state.Loading} />
                <DataGrid
                    columns={columnsToRender}
                    rows={this.state.DTRDetails}
                    rowsCount={this.state.DTRDetails.length}
                    minHeight={this.state.gridHeight}
                //getCellActions={getCellActions}
                />
        </div>
    }
}
export default DTRList