import React, { Component } from 'react'
import { empty_id, isTaskAvailable, ValidateNumeric } from '../../utils'
import GroupFilterModal from '../../Commons/GroupFilterModal';
import SelectFilterEmployees from '../../Commons/SelectFilterEmployees';
import { ajaxPost } from '../../ajax';
import MessageBoxOkOnly from '../../Commons/MessageBoxOkOnly';
import MessageBoxYesNo from '../../Commons/MessageBoxYesNo';
import fileDialog from 'file-dialog';
import { Button, ButtonGroup, Icon, Image, Input, Modal, Pagination, Table, TableCell, TableRow } from 'semantic-ui-react';
import moment from 'moment';

const blankModel = {
    // _id: empty_id,
    EmployeeId: empty_id,
    Year: moment().format('YYYY'),
    BasicSMW: 0,
    HolidayPayNT: 0,
    OvertimePayNT: 0,
    NightDifferentialNT: 0,
    HazardPayNT: 0,
    ThirteenthMonthPayAndOtherBenefitsNT: 0,
    DeminimisNT: 0,
    GovtContribution: 0,
    SalariesAndOtherCompensationNT: 0,
    BasicSalaryT: 0,
    ThirteenthMonthPayAndOtherBenefitsT: 0,
    SalariesAndOtherCompensationT: 0,
    TaxWithheld: 0
}

const labelStyle = {
    display: 'block',
    width: '160px',
    height: '40px'
}

class PreviousEmployerList extends Component {
    constructor(props) {
        super(props)

        this.state = {
            activePage: 1,
            advanceFilter: {
                companyIds: [],
                divisionIds: [],
                departmentIds: [],
                locationIds: [],
                positionIds: []
            },
            exportTemplateLoading: false,
            formModel: JSON.parse(JSON.stringify(blankModel)),
            formOpen: false,
            formTitle: '',
            importLoading: false,
            isDataLoading: false,
            isFilterActive: false,
            isSuperAdmin: global.userId === empty_id,
            msgboxConfirmationOpen: false,
            msgboxContent: '',
            msgboxOpen: false,
            msgboxTitle: '',
            selectedEmployeeIds: [],
            previousEmployerList: [],
            resultCount: 0,
            userCanAdd: isTaskAvailable("PREVIOUS_EMPLOYER_ADD"),
            userCanDelete: isTaskAvailable("PREVIOUS_EMPLOYER_DELETE"),
            userCanEdit: isTaskAvailable("PREVIOUS_EMPLOYER_EDIT"),
            
        }
    }

    componentDidMount() {
        this.getData();
    }

    componentWillUnmount = () => {
        this.props.unmountCallback(this.state, "PreviousEmployerModule");
    }

    advanceFilterCallBack = () => {

    }

    checkEmptyValues = (value) => {
        if(value === undefined || value === '') {
            return 0;
        }

        return value;
    }

    getData = () => {
        this.setState({isDataLoading: true})
        ajaxPost({
            url: 'api/PreviousEmployer/list',
            data: {
                EmployeeIds: this.state.selectedEmployeeIds,
                StartIndex: (this.state.activePage - 1) * 10,
                ItemCount: 10,
                AdvanceFilter: this.state.advanceFilter,
                AdditionalParameter: -1
            },
            onSuccess: (data) => {
                this.setState({previousEmployerList: data.Data, resultCount: data.TotalCount})
            },
            finally: () => {
                this.setState({isDataLoading: false})
            }
        })
    }

    handleFilterClick = () => {

    }

    handlePaginationChange = (event, { activePage }) => {
        this.setState({activePage}, this.getData)
    }

    onAdd = () => {
        this.setState({
            formModel: JSON.parse(JSON.stringify(blankModel)),
            formOpen: true,
            formTitle: 'Add Previous Employer'
        })
        
    }

    onClickSearch = () => {
        this.getData();
    }

    onDelete = (item) => {
        this.setState({
            formModel: item,
            msgboxConfirmationOpen: true
        })
    }

    onDeleteCancel = () => {
        this.setState({msgboxConfirmationOpen: false})
    }

    onDeleteConfirm = () => {
        const {formModel, previousEmployerList} = this.state;
        ajaxPost({
            url: 'api/PreviousEmployer/delete',
            data: formModel._id,
            onSuccess: () => {
                previousEmployerList.splice(previousEmployerList.findIndex(item => item._id == formModel._id),1)
                this.setState({
                    msgboxConfirmationOpen: false,
                    msgboxContent: <div> Successfully Deleted. </div>,
                    msgboxOpen: true,
                    msgboxTitle: "Previous Employer",
                    previousEmployerList
                })
            },
            finally: () => { 

            }
        })
    }

    onDownloadTemplateClick = () => {
        if (this.state.exportTemplateLoading)
        return;
        this.setState({ exportTemplateLoading: true })
        window.location = window.ApiHost + "api/PreviousEmployer/downloadTemplate"
        this.setState({ exportTemplateLoading: false })
    }

    onEdit = (item) => {
        this.setState({
            formModel: JSON.parse(JSON.stringify(item)),
            formOpen: true,
            formTitle: 'Edit Previous Employer'
        })
    }

    onFormEmployeeChanged = (ids) => {
        const {formModel} = this.state;
        formModel.EmployeeId = ids.length > 0? ids[0]: empty_id;
        this.setState({formModel});
    }

    onImportClick = () => {
        if (this.state.importLoading)
            return;

        fileDialog().then(file => {
            const data = new FormData();
            data.append('file', file[0]);
            data.append('RequestClass', JSON.stringify({
                SessionKey: 'HRIS_SessionId',
                SessionId: global.sessionId
            }
            )
            );
            this.setState({ importLoading: true });
            fetch(window.ApiHost + 'api/PreviousEmployer/importPreviousEmployer', {
                method: 'POST',
                credentials: 'include',
                body: data
            }).then((resp) => {
                this.setState({ importLoading: false });
                return resp.json();
            }).then((json) => {
                var data = JSON.parse(json.JsonData);
                this.setState({
                    msgboxOpen: true,
                    msgboxTitle: "Import Previous Employer",
                    msgboxContent:
                        <div>
                            <p>{data.content}</p>
                            {data.errors.map(err => { return (<span>{err}<br /></span>) })}
                        </div>
                })
                this.setState({ importLoading: false });
            })
        });
    }

    onSave = () => {
        const {formModel} = this.state;

        if(formModel.EmployeeId === undefined || formModel.EmployeeId === null || formModel.EmployeeId === empty_id) {
            alert("Please select an employee.")
            return;
        }

        if(formModel.Year < 1900) {
            alert("Invalid Year.")
            return;
        }
        
        formModel.BasicSMW = this.checkEmptyValues(formModel.BasicSMW)
        formModel.HolidayPayNT = this.checkEmptyValues(formModel.HolidayPayNT)
        formModel.OvertimePayNT = this.checkEmptyValues(formModel.OvertimePayNT)
        formModel.NightDifferentialNT = this.checkEmptyValues(formModel.NightDifferentialNT)
        formModel.HazardPayNT = this.checkEmptyValues(formModel.HazardPayNT)
        formModel.ThirteenthMonthPayAndOtherBenefitsNT = this.checkEmptyValues(formModel.ThirteenthMonthPayAndOtherBenefitsNT)
        formModel.DeminimisNT = this.checkEmptyValues(formModel.DeminimisNT)
        formModel.GovtContribution = this.checkEmptyValues(formModel.GovtContribution)
        formModel.SalariesAndOtherCompensationNT = this.checkEmptyValues(formModel.SalariesAndOtherCompensationNT)
        formModel.ThirteenthMonthPayAndOtherBenefitsT = this.checkEmptyValues(formModel.ThirteenthMonthPayAndOtherBenefitsT)
        formModel.SalariesAndOtherCompensationT = this.checkEmptyValues(formModel.SalariesAndOtherCompensationT)
        formModel.TaxWithheld = this.checkEmptyValues(formModel.TaxWithheld)
        
        ajaxPost({
            url: 'api/PreviousEmployer/save',
            data: formModel,
            onSuccess: (data) => {
                if("errors" in data && data.errors.length > 0) {
                    this.setState({
                        msgboxContent: <div> {data.errors.map(err => { return (<span>{err}<br /></span>) })} </div>,
                        msgboxOpen: true,
                        msgboxTitle: "Previous Employer",
                        
                    })

                    return;
                }

                this.setState({
                    formOpen: false,
                    msgboxContent: <div> Successfully Save. </div>,
                    msgboxOpen: true,
                    msgboxTitle: "Previous Employer",
                }, this.getData)
            },
            finally: () => {

            }
        })
    }

    onTextChanged = (e) => {
        const {formModel} = this.state;
        const field = e.target.name
        let originalText = e.target.value
        let text = originalText

        if(text === undefined)
            text = 0;

        if(field === 'Year') {
            text = text.replace(/\D+/g, '')

            if(text.length > 4) {
                text = text.substring(0, 4)
            }

            if(text !== '') {
                text = parseInt(text)
            }
                
        }
        else {
            text = ValidateNumeric(text)
        }

        formModel[field] = text;
        this.setState({formModel});
    }

    render() {
        return(
        <div>
            <div style={{ color: "#606060", marginTop: '1%' }}>
                <h2>Previous Employer</h2>
            </div>

            <div className='w3-row' style={{ paddingTop: '15px' }}>
                <Pagination
                    floated='right'
                    activePage={this.state.activePage}
                    defaultActivePage={1}
                    pointing
                    secondary
                    firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                    lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                    prevItem={{ content: <Icon name='angle left' />, icon: true }}
                    nextItem={{ content: <Icon name='angle right' />, icon: true }}
                    size='mini'
                    siblingRange={2}
                    boundaryRange={0}
                    totalPages={Math.ceil(this.state.resultCount / 10)}
                    onPageChange={this.handlePaginationChange}
                />
            </div>

            <div className='w3-row' style={{ marginTop: '15px' }}>
                <div className='w3-col m6 l8'>
                    <Button floated='left' size='mini' onClick={this.onAdd}
                        style={{ 'max-height': '31px' }} disabled={!(this.state.isSuperAdmin || this.state.userCanAdd)}>
                        <Icon name='plus' /> Add </Button>

                    <Button floated='left' size='mini' loading={this.state.importLoading} onClick={this.onImportClick}
                        style={{ 'max-height': '31px' }} disabled={!(this.state.isSuperAdmin || this.state.userCanAdd)}>
                        <Icon name='file excel' />Import</Button>

                    <Button floated='left' size='mini' loading={this.state.exportTemplateLoading} onClick={this.onDownloadTemplateClick}
                        style={{ 'max-height': '31px' }} disabled={!(this.state.isSuperAdmin || this.state.userCanAdd)}>
                        <Icon name='download' />Template</Button>
                </div>

                <div className='w3-col m6 l4' style={{ paddingTop: '5px', paddingLeft: '3px', paddingRight: '3px' }}>
                    {/* <div className='w3-col l1' >
                        <Button style={{ width: '100%', 'max-height': '36px', height: '36px' }} size="mini" icon="filter"
                            onClick={this.handleFilterClick.bind(this, true)}
                        />
                    </div> */}
                    <div className='w3-col l10' >
                        <SelectFilterEmployees
                            ref={(e) => this.searchFilter = e}
                            ids={this.state.selectedEmployeeIds}
                            companyIds={this.state.advanceFilter.companyIds}
                            departmentIds={this.state.advanceFilter.departmentIds}
                            divisionIds={this.state.advanceFilter.divisionIds}
                            locationIds={this.state.advanceFilter.locationIds}
                            positionIds={this.state.advanceFilter.positionIds}
                            includeDeleted={false}
                            update={(ids) => this.setState({ selectedEmployeeIds: ids })}
                            style={{ width: '100%' }} />
                    </div>
                    <div className='w3-col l2' style={{ paddingLeft: '3px', paddingRight: '3px' }}>
                            <Button fluid style={{ 'max-height': '36px',height:'36px' }}  size='mini' loading={this.state.isDataLoading} onClick={
                                () => {
                                    this.setState({ activePage: 1 },
                                        () => this.getData()
                                    )
                                }
                            }>
                                <Icon name="search" />View
                            </Button>
                        </div>
                </div>
            </div>
            <br />
            <div className='w3-row' style={{ marginTop: '.5%' }}>
                {this.state.previousEmployerList != null &&
                    <Table fixed striped padded size='small'>
                        <Table.Header>
                            <Table.Row style={{ 'backgroundColor': 'gainsboro', 'fontSize': '11px', padding: '0px' }}>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.HeaderCell>Year</Table.HeaderCell>
                                <Table.HeaderCell textAlign='right' style={{ 'max-width': '50px', paddingRight: 30}}>Action</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.state.previousEmployerList.map(list => {
                                return (
                                    <Table.Row selected key={list._id} negative={list.Deleted} >
                                        <Table.Cell>{list.EmployeeName}</Table.Cell>
                                        <Table.Cell>{list.Year}</Table.Cell>
                                        <Table.Cell>
                                            <ButtonGroup size='mini' floated='right' vertical>
                                                <Button size='mini' onClick={this.onEdit.bind(this, list)} disabled={!this.state.userCanEdit}>Edit</Button>
                                                <Button size='mini' onClick={this.onDelete.bind(this, list)} disabled={!this.state.userCanDelete}>Delete</Button>
                                            </ButtonGroup>
                                        </Table.Cell>
                                    </Table.Row>
                                )
                            })}
                        </Table.Body>
                    </Table>
                }
            </div>

            {this.state.formOpen && <Modal open={this.state.formOpen}>
                <Modal.Header>{this.state.formTitle}</Modal.Header>
                <Modal.Content>
                    <div className='w3-row'>
                        <div className='w3-col l3'>
                            <label>Employee</label>
                        </div>
                        <div className='w3-col l9'>
                            <div className='w3-col l9'>
                                <SelectFilterEmployees
                                    ids={[this.state.formModel.EmployeeId]}
                                    includeDeleted={false}
                                    isMulti={false}
                                    update={this.onFormEmployeeChanged}
                                    style={{ width: '100%' }} 
                                />
                            </div>
                        </div>
                    </div>
                    <br/>
                    <div className='w3-row' >
                        <div className='w3-col l3'>
                            <label>Year</label>
                        </div>
                        <div className='w3-col l9'>
                            <Input name='Year' value={this.state.formModel.Year} onChange={this.onTextChanged}/>
                        </div>
                    </div>
                    <br/>
                    <Modal.Description>Non Taxable Income</Modal.Description>
                    <Table>
                        <TableRow>
                            <TableCell><label style={labelStyle}>Basic SMW</label><Input name='BasicSMW' value={this.state.formModel.BasicSMW} onChange={this.onTextChanged}/></TableCell>
                            <TableCell><label style={labelStyle}>Holiday Pay</label><Input name='HolidayPayNT' value={this.state.formModel.HolidayPayNT} onChange={this.onTextChanged}/></TableCell>
                            <TableCell><label style={labelStyle}>Overtime Pay</label><Input name='OvertimePayNT' value={this.state.formModel.OvertimePayNT} onChange={this.onTextChanged}/></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><label style={labelStyle}>Night Differential</label><Input name='NightDifferentialNT' value={this.state.formModel.NightDifferentialNT} onChange={this.onTextChanged}/></TableCell>
                            <TableCell><label style={labelStyle}>Hazard Pay</label><Input name='HazardPayNT' value={this.state.formModel.HazardPayNT} onChange={this.onTextChanged}/></TableCell>
                            <TableCell><label style={labelStyle}>Thirteenth Month Pay And Other Benefits</label><Input name='ThirteenthMonthPayAndOtherBenefitsNT' value={this.state.formModel.ThirteenthMonthPayAndOtherBenefitsNT} onChange={this.onTextChanged}/></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell><label style={labelStyle}>Deminimis</label><Input name='DeminimisNT' value={this.state.formModel.DeminimisNT} onChange={this.onTextChanged}/></TableCell>
                            <TableCell><label style={labelStyle}>Government Contribution</label><Input name='GovtContribution' value={this.state.formModel.GovtContribution} onChange={this.onTextChanged}/></TableCell>
                            <TableCell><label style={labelStyle}>Salaries And Other Compensation</label><Input name='SalariesAndOtherCompensationNT' value={this.state.formModel.SalariesAndOtherCompensationNT} onChange={this.onTextChanged}/></TableCell>
                        </TableRow>
                    </Table>
                    <Modal.Description>Taxable Income</Modal.Description>
                    <Table>
                        <TableRow>
                            <TableCell><label style={labelStyle}>Basic Salary</label><Input name='BasicSalaryT' value={this.state.formModel.BasicSalaryT} onChange={this.onTextChanged}/></TableCell>
                            <TableCell><label style={labelStyle}>Thirteenth Month Pay And Other Benefits</label><Input name='ThirteenthMonthPayAndOtherBenefitsT' value={this.state.formModel.ThirteenthMonthPayAndOtherBenefitsT} onChange={this.onTextChanged}/></TableCell>
                            <TableCell><label style={labelStyle}>Salaries And Other Compensation</label><Input name='SalariesAndOtherCompensationT' value={this.state.formModel.SalariesAndOtherCompensationT} onChange={this.onTextChanged}/></TableCell>
                        </TableRow>
                    </Table>
                    <Modal.Description>Tax</Modal.Description>
                    <Table>
                        <TableRow>
                            <TableCell><label style={labelStyle}>Tax Withheld</label><Input name='TaxWithheld' value={this.state.formModel.TaxWithheld} onChange={this.onTextChanged}/></TableCell>
                        </TableRow>
                    </Table>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic icon='check' content='Save' onClick={this.onSave}></Button>
                    <Button basic content='Cancel' onClick={() => this.setState({formOpen: false})}></Button>
                </Modal.Actions>
            </Modal>}

            <GroupFilterModal
                advFilter={this.state.advanceFilter}
                hideGroups={["COMPANY"]}
                open={this.state.isFilterActive}
                onCancel={this.handleFilterClick.bind(this, false)}
                callback={this.advanceFilterCallBack.bind(this)}
            />

            {this.state.msgboxConfirmationOpen && <MessageBoxYesNo action='Yes' title='Delete Previous Employer' caption='Are you sure you want to delete this item?' cancelCaption='No' onAction={this.onDeleteConfirm} onClose={this.onDeleteCancel}/>}
            {this.state.msgboxOpen && <MessageBoxOkOnly title={this.state.msgboxTitle} caption={this.state.msgboxContent} onClick={() => this.setState({ msgboxOpen: false })} />}
        </div>)
    }
}

PreviousEmployerList.getViewID = () => { return "PreviousEmployerModule" };
PreviousEmployerList.getCaption = () => { return "Previous Employer" }
PreviousEmployerList.getViewAccessKey = () => {
    return isTaskAvailable("PREVIOUS_EMPLOYER_VIEW")
}
PreviousEmployerList.getIcon = () => {
    return (<Image style={{ "height": "24px", "width": "24px" }} src='Icons/Position_Assignment.png' avatar />);
}
PreviousEmployerList.getIconSrc = (size) => {
    return 'Icons/Position_Assignment.png';
}

export default PreviousEmployerList;